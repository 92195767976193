import {
  get,
  post,
  update,
} from '../helpers'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        get('/tpl-pemeriksaan-lanjutan', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, { id }) {
      return new Promise((resolve, reject) => {
        get(`/tpl-pemeriksaan-lanjutan/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addData(ctx, userData) {
      return new Promise((resolve, reject) => {
        post('/tpl-pemeriksaan-lanjutan', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateData(ctx, userData) {
      return new Promise((resolve, reject) => {
        update('/tpl-pemeriksaan-lanjutan', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDataKeterampilan(ctx, { id }) {
      return new Promise((resolve, reject) => {
        get(`/keterampilan/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDataKeterampilan(ctx, userData) {
      return new Promise((resolve, reject) => {
        post('/keterampilan', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeDataKeterampilan(ctx, userData) {
      return new Promise((resolve, reject) => {
        update('/keterampilan', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDataRoles(ctx, { id }) {
      return new Promise((resolve, reject) => {
        get(`/roles/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDataRoles(ctx, userData) {
      return new Promise((resolve, reject) => {
        post('/roles', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeDataRoles(ctx, userData) {
      return new Promise((resolve, reject) => {
        update('/roles', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
