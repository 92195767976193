<template>

  <div>
     <!-- Tambah Data -->
    <!-- <div>
      <b-button class="btn mb-2" @click="TambahData()" variant="outline-primary" v-if="$can('manage','all')">Tambah Agama</b-button>
      <tambah-data
        v-if="showTambahData === true"
        :negaraOptions="negaraOptions.options"
        :provinsiOptions="provinsiOptions.options"
        :kotaOptions="kotaOptions.options"
        @emitLoadKota="onchangeProvinsi"
        @refetchData="refetchData"
        @emitPilihInstansi="pilihInstansi"
        @emitCloseTambahData="closeTambahData"
        @closeTambahData="closeTambahData"
       />
       <edit-data
        v-if="showEditData === true"
        :instansiId="instansiId"
        @emitCloseEditData="closeEditData"
        :negaraOptions="negaraOptions.options"
        :provinsiOptions="provinsiOptions.options"
        :kotaOptions="kotaOptions.options"
        @emitLoadKota="onchangeProvinsi"
        @refetchData="refetchData"
        @emitReloadDataKerjasama="reloadDataKerjasama"
       />
    </div> -->

    <!-- Filters -->
    <!-- <list-filters
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-row class="mb-5">
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h5>TPL PEMERIKSAAN LANJUTAN</h5>
            </b-col>
          
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              <b-dropdown id="dropdown-1" text="Export" variant="primary" class="m-md-2">
                <b-dropdown-item><feather-icon icon="FileIcon" /> Excel</b-dropdown-item>
              </b-dropdown>
            </b-col>
        </b-row>

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(status_pemeriksaan)="data">
          <b-media vertical-align="center">
              
              <b-badge pill :variant="`light-${data.item.status.class}`">{{ data.item.status.nama }}</b-badge>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
            <div>
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="outline-primary" v-b-modal="`modal-detail-${data.item.id}`" title="Detail">
                <feather-icon icon="SearchIcon" />
              </b-button>
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="primary" :to="{ name: 'tpl-pemeriksaan-lanjutan-edit', params: { laporan_id: data.item.id, mode: 'pemeriksaan' } }" title="Lihat Data">
                <feather-icon icon="EyeIcon" />
              </b-button>
            </div>
            <div>
              <b-modal :id="`modal-detail-${data.item.id}`" centered  title="Detail Monitoring Pemeriksaan" hide-footer>
                <b-row>
                  <b-col
                    xl="12"
                  >
                    <b-form-group
                      label="No Register"
                      label-for="No Register"
                    >
                      <b-form-input
                        id="no_register"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        :value="data.item.no_register"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Tgl Register"
                      label-for="tgl_register"
                    >
                      <b-form-input
                        id="tgl_register"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                       :value="data.item.tgl_register"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Nama Tim"
                      label-for="nama_tim"
                    >
                      <b-form-input
                        id="nama_tim"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        :value="data.item.nama_tim"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Penanggung Jawab"
                      label-for="penanggung_jawab"
                    >
                      <b-form-input
                        id="penanggung_jawab"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                    
                      />
                    </b-form-group>
                    <b-form-group
                      label="Status"
                      label-for="status"
                    >
                      <b-form-input
                        id="status"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        :value="data.item.status.nama"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Durasi Pengerjaan"
                      label-for="Durasi Pengerjaan"
                    >
                      <b-form-input
                        id="Durasi Pengerjaan"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Waktu Proses Terakhir"
                      label-for="Waktu Proses Terakhir"
                    >
                      <b-form-input
                        id="Waktu Proses Terakhir"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        :value="data.item.waktu_proses_terakhir"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Created Date"
                      label-for="Created Date"
                    >
                      <b-form-input
                        id="Created Date"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.created_at"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Created By"
                      label-for="Created By"
                    >
                      <b-form-input
                        id="Created By"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.created_by"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Last Update Date"
                      label-for="Last Update Date"
                    >
                      <b-form-input
                        id="Last Update Date"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.updated_at"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Last Update By"
                      label-for="Last Update By"
                    >
                      <b-form-input
                        id="Last Update By"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                      
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-modal>
            </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} per {{ dataMeta.to }} dari {{ dataMeta.of }} data </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/tpl-pemeriksaan-lanjutan/storeModule'
import useList from '@/connection-api/tpl-pemeriksaan-lanjutan/list'
import TambahData from './TambahData.vue'
import EditData from './EditData.vue'
import listFilters from './ListFilters.vue'
import {
  getNegara,
  getProvinsi,
  getKota,
  deleteAgama,
} from '@/connection-api/master'
// import {
//   pilihUnitUtama,
// } from '@/connection-api/kerjasama'
// import {
//   undangUnitUtama,
// } from '@/connection-api/jadwal-pembahasan'

export default {
  components: {
    listFilters,
    TambahData,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    EditData,
    vSelect,
    BModal,
    BFormGroup,
    BFormTextarea,
    BBadge,
  },
  data() {
    return {
      instansiId: null,
      showTambahData: false,
      showEditData: false,
      negaraOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Negara --' },
        ],
      },
      provinsiOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Provinsi --' },
        ],
      },
      kotaOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kota --' },
        ],
      },
    }
  },
  props: {
    pihak: {},
    jadwal_pembahasan_id: {},
  },
  async mounted() {
      const respNegara = await getNegara()
      this.mappingSelectOption(respNegara.data, this.negaraOptions.options)

      const respProvinsi = await getProvinsi()
      this.mappingSelectOption(respProvinsi.data, this.provinsiOptions.options)

      if(this.$route.params.id)
      {
        this.dataKerjasama = {
          kerjasama_id: this.$route.params.id,
          pihak_ke: this.pihak,
        }
      }
      if(this.jadwal_pembahasan_id)
      {
        this.peserta = {
          jadwal_pembahasan_id: this.jadwal_pembahasan_id,
        }
      }
    },
  methods: {
    closeEditData(){
      this.showEditData = false
    },
    closeTambahData(){
      this.showTambahData = false
    },
    editData(id) {
      this.showTambahData = false
      this.showEditData = false
      this.instansiId = id
      this.showEditData = true
    },
    async pilihInstansi(id)
    {
      const data = {
        unit_utama_id: id,
        kerjasama_id: this.$route.params.id,
        pihak_ke: this.pihak,

      }
      const response = await pilihUnitUtama(data)
      if(response.data.success === true)
      {
         this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Agama',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.refetchData()
            this.reloadDataKerjasama()
      }
    },
    async undang(id, jadwalPembahasanId)
    {
      const data = {
        jadwal_pembahasan_id: jadwalPembahasanId,
        unit_utama_id: id,
      }

      const response = await undangUnitUtama(data)
      if(response.data.success === true)
      {
         this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Agama',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.refetchData()
      }
    },
    reloadDataKerjasama() {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    async deleteData(id, nama) 
    {
      if(window.confirm(`Apakah anda yakin ingin menghapus Agama ${nama} ?`))
      {
        const data = {
          id: id
        }
        const response = await deleteAgama(data)
        if(response.data.success === true)
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Agama',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Data berhasil di hapus',
              },
            })
            this.refetchData()
        }
        else
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Agama',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.message,
              },
            })
        }
      }
    },
    async onchangeProvinsi(provinsiId)
    {
      const data = {
        provinsi_id: provinsiId
      }
      this.kotaOptions = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kota --' },
        ],
      }
      const respKota = await getKota(data)
      this.mappingSelectOption(respKota.data, this.kotaOptions.options)
    },
    TambahData()
    {
      if(this.showTambahData === false)
      {
        this.showTambahData = true;
        this.showEditData = false
      }
      else
      {
        this.showTambahData = false;
        this.showEditData = false
      }
    },
    emitAddSuccess(assetId) {
      this.$router.replace(`/agama/edit/${assetId}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Agama',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil di tambahkan',
            },
          })
        })
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'tpl-pemeriksaan-lanjutan'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      dataKerjasama,
      peserta,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      dataKerjasama,
      peserta,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
